@import "../../../styles/base/abstracts";

.hbs-page-section {
  @include breakout-full;
  @include block-spacing(both, padding, xl);
  //overflow: hidden;
  position: relative;

  &.hbs-page-section--top {
    padding-bottom: 0;
  }

  &.hbs-page-section--bottom {
    padding-top: 0;
  }

  + .hbs-page-section {
    margin-top: 0;
  }

  + *:not(.hbs-page-section):not(hr) {
    @include block-spacing(top, $size: lg);
  }

  &.hbs-page-section--center,
  &.hbs-page-section--wide {
    background-color: transparent;
  }

  .hbs-article-page & {
    @include block-spacing(top, padding, lg);
    @include block-spacing(bottom, padding, lg);
  }
}

.hbs-page-section__width {
  @include grid-parent;
  @include site-max-width;
}

.hbs-page-section__content {
  @include grid-child;

  @include mq($bp-desktop) {
    margin-left: $col-4;
    margin-right: 0;
    width: $col-9;
  }

  @include mq($bp-desktop-xl) {
    width: $col-8;
  }
}
